export type UrlItem = {
  label: string;
  url: string;
};

export const URL_SETTINGS = {
  AUTH_CALLBACK: { label: 'Authentication callback', url: '/authentication' },
  CONSENT_CALLBACK: { label: 'Consent callback', url: '/redirect' },
  CONSENT_CREATE: { label: 'Create consent', url: '/create' },
  CONSENT_DETAIL: { label: 'Consent', url: '/consent/:consentId' },
  CONSENT_EDIT: { label: 'Edit your consent', url: '/consent/edit/:consentId' },
  CONSENT_LIST: { label: 'Manage your consents', url: '/' },
  ERROR: { label: 'Error', url: '/error' },
  OTP_SIGN_IN: { label: 'Sign in with your One Time Password', url: '/otp-sign-in' },
  PASSWORDLESS_SIGN_IN: { label: 'Passwordless sign', url: '/passwordless-sign-in' },
  POST_CONSENT_FLOW: { label: 'Add another consent ', url: '/post-consent' },
  STATUS: { label: 'Status page', url: '/status' },
  LOGOUT: { label: 'Logout', url: '/logout' },
  TEST_LAB: { label: 'Test Lab', url: '/test-lab' },
};
