import { useAuth as useOidcAuth } from 'react-oidc-context';
import { useEffect, useState } from 'react';
import { State } from '../../state/state';
import { CONSTANTS } from '../../consts/app.const';
import { useNavigate } from 'react-router-dom';
import { URL_SETTINGS } from '../../settings/url.settings';
import { OtpSignInResponse } from '../../types/auth.type';
import { AuthUtil } from '../../../utils/auth/auth.util';
import { AuthType } from '../../../context/authentication/auth.context';

export type UseAuth = {
  isAuthenticated: boolean;
  isLoading: boolean;
  postSignIn: (response: OtpSignInResponse, granteeID: string) => void;
  querySessionStatus: () => void;
  signIn: () => void;
  signOut: () => void;
  error: Error;
};
export const useAuth = (): UseAuth => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const oidcAuth = useOidcAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (State.getAuthType() === AuthType.OIDC && oidcAuth) {
      setIsAuthenticated(oidcAuth.isAuthenticated);
      setIsLoading(oidcAuth.isLoading);
      setError(oidcAuth.error);
    }
  }, [oidcAuth]);

  useEffect(() => {
    if (State.getAuthType() === AuthType.OTP) {
      setIsLoading(false);
    }
  }, [oidcAuth]);

  /**
   *
   * Post sign in
   *
   */
  const postSignIn = (response: OtpSignInResponse, granteeId: string) => {
    if (State.getAuthType() === AuthType.OIDC) {
      postSignInOidc();
    } else if (State.getAuthType() === AuthType.OTP) {
      postSignInOtp(response, granteeId);
    }
  };

  const postSignInOidc = () => {};

  const postSignInOtp = (otpSignInResponse: OtpSignInResponse, granteeId: string) => {
    State.setOtpAuthManager({
      accessToken: otpSignInResponse.accessToken,
      accessTokenExpires: otpSignInResponse.accessTokenExpires,
      email: otpSignInResponse.email,
      granteeId: granteeId,
      mobile: '',
    });

    sessionStorage.setItem(CONSTANTS.storageKeys.otpAuthManager, JSON.stringify(State.getOtpAuthManager()));
  };

  const querySessionStatus = async () => {
    try {
      const sessionInfo = await oidcAuth.querySessionStatus();
      if (sessionInfo) {
        console.log('Success session info ', sessionInfo);
      }
    } catch (e) {
      if (e.message === 'login_required') {
        console.log('Automatic Sign started');
      }
    }
  };

  /**
   *
   * Sign in
   *
   */
  const signIn = () => {
    // Save current URL including any parameters to return user to on callback
    localStorage.setItem(CONSTANTS.storageKeys.returnUri, window.location.pathname + window.location.search);

    if (State.getAuthType() === AuthType.OIDC) {
      signInOidc();
    } else if (State.getAuthType() === AuthType.OTP) {
      signInOtp();
    }
  };

  const signInOidc = () => {
    // Sign in
    oidcAuth.signinRedirect();
  };

  const signInOtp = () => {
    const authState = State.getOtpAuthManager();

    if (authState && authState.accessToken) {
      setIsAuthenticated(true);
    } else {
      navigate(URL_SETTINGS.OTP_SIGN_IN.url);
    }
  };

  /**
   *
   * Sign out
   *
   */
  const signOut = () => {
    AuthUtil.signout();
  };

  return {
    isAuthenticated,
    isLoading,
    postSignIn,
    querySessionStatus,
    signIn,
    signOut,
    error,
  };
};
