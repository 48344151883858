import {
  CreateConsent,
  UseCaseResponse,
  useConsentForm,
  FeedbackMessage,
  ConsentResponse,
  Logger,
  PostUsageAction,
} from '@adatree/atomic-components';
import { ReactNode, useEffect, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { AuthUtil } from '../../../utils/auth/auth.util';
import { State } from '../../../app/state/state';
import { useConsentRepository } from '../../../app/hooks/repositories/consent-repository/useConsentRepository';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { ConsentCreateOneUseCase } from './consent-create-one-use-case.organism';
import { ConsentCreateManyUseCase } from './consent-create-many-use-case.organism';
import { handleErrorRedirect } from '../../../utils/errors/errors.util';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { AuthType } from '../../../context/authentication/auth.context';
import { CONSTANTS } from '../../../app/consts/app.const';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';

interface Props {
  useCaseId?: string;
}

export const ConsentCreate = ({ useCaseId }: Props) => {
  const [consentRepository] = useConsentRepository();
  const [consentForm, setConsentForm] = useConsentForm();
  const [useCase, setUseCase] = useState<UseCaseResponse>();
  const [useCases, setUseCases] = useState<UseCaseResponse[]>();
  const [existingConsents, setExistingConsents] = useState<ConsentResponse[]>();
  const [feedback, setFeedback] = useState<ReactNode>();
  const { appSettings } = useAppSettings();

  /**
   * Handlers
   */
  const handleUseCaseChange = (useCaseId) => {
    const foundUseCase = useCases.filter((useCase) => {
      return useCase.id === useCaseId;
    });

    consentForm.useCaseId = useCaseId;
    setConsentForm({ ...consentForm });
    setUseCase(foundUseCase[0]);
  };

  const handleUserCancel = () => {
    const errorCode = CONSTANTS.errors.userOptOut.code;
    const errorMessage = CONSTANTS.errors.userOptOut.description;

    if (appSettings.consent.errorRedirectUrl) {
      handleErrorRedirect(appSettings.consent.errorRedirectUrl, errorCode, errorMessage);
    } else {
      Logger.info(errorMessage, `Error code: ${errorCode}`);
      window.location.href = URL_SETTINGS.CONSENT_CREATE.url;
    }
  };

  /**
   * Submit the form
   */
  const handleSubmit = () => {
    let email = AuthUtil.getEmail(appSettings);
    let mobileNumber = AuthUtil.getMobileNumber(appSettings);

    if (email === '' && mobileNumber === '') {
      Logger.error('User email and mobile not set');
    }

    let consent: CreateConsent = {
      consumerEmail: email,
      dataHolderBrandId: consentForm.dataHolder.dataHolderBrandId,
      directMarketingAllowed: false,
      postUsageAction: PostUsageAction.Deletion,
      sharingEndDate: consentForm.sharingEndDate.toISOString(),
      useCaseId: consentForm.useCaseId,
      customData: consentForm.customData,
    };

    if (State.getAuthType() === AuthType.OTP) {
      // @ts-ignore
      let granteeId = State.getOtpAuthManager().granteeId;
      if (granteeId) {
        consent.grantee = { id: granteeId };
      }
    }

    const createdConsent = async () => {
      try {
        renderFeedback('Saving your consent request...', true);
        const createdConsent = await consentRepository.createConsent(consent);

        renderFeedback(`Please wait while we redirect you to ${consentForm.dataHolder.brandName}`);
        const redirect = await consentRepository.authorization(
          createdConsent.dataHolderBrandId,
          createdConsent.consentId
        );

        window.location.href = redirect;
      } catch (error) {
        renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
      }
    };

    createdConsent();
  };

  /**
   * Feedback
   */
  const renderFeedback = (message: string, isLoading = false, isError = false) => {
    const icon =
      isError === true ? (
        <AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />
      ) : (
        <Check sx={{ fontSize: '56px', color: 'primary.main' }} />
      );

    setFeedback(<FeedbackMessage message={message} icon={icon} showSpinner={isLoading} />);
  };

  /**
   * Call the APIs
   */
  useEffect(() => {
    consentRepository
      .findAllUseCases()
      .then((foundUseCases: UseCaseResponse[]) => {
        setUseCases(foundUseCases);

        if (useCaseId) {
          const useCaseIndex = foundUseCases.findIndex((useCase) => {
            return useCase.id === useCaseId;
          });
          if (useCaseIndex > -1) {
            setUseCase(foundUseCases[useCaseIndex]);
            consentForm.useCaseId = foundUseCases[useCaseIndex].id;
            setConsentForm({ ...consentForm });
          } else {
            Logger.error(`Use case ID "${useCaseId}" does not exist in use cases:`, foundUseCases);
          }
        }

        if (foundUseCases.length === 1) {
          setUseCase(foundUseCases[0]);
          consentForm.useCaseId = foundUseCases[0].id;
          setConsentForm({ ...consentForm });
        }
      })
      .catch((error) => {
        // This error needs to be handled
        Logger.error('Error calling useCaseRepository.findAll()');
        throw new Error(error);
      });
    // eslint-disable-next-line
  }, [consentRepository]);

  useEffect(() => {
    consentRepository
      .findAllActiveConsents()
      .then((foundConsents: ConsentResponse[]) => {
        setExistingConsents(foundConsents);
      })
      .catch((error) => {
        // This error needs to be handled
        Logger.error('Error calling consentRepository.findAll()');
        throw new Error(error);
      });
  }, [consentRepository]);

  /**
   * Render
   */
  return (
    <Box>
      {feedback && feedback}
      {!feedback && !useCases && (
        <>
          <Skeleton sx={{ mb: 4.2, height: '3.5rem' }} />
          <Skeleton sx={{ mb: 2, height: '4rem' }} />
          <Skeleton sx={{ m: 1, height: '12rem' }} />
        </>
      )}
      {!feedback && useCase && existingConsents && (
        <ConsentCreateOneUseCase
          existingConsents={existingConsents}
          useCase={useCase}
          onSubmit={handleSubmit}
          onCancel={handleUserCancel}
        />
      )}
      {!feedback && !useCase && useCases && useCases.length > 1 && (
        <ConsentCreateManyUseCase useCases={useCases} onChange={handleUseCaseChange} onCancel={handleUserCancel} />
      )}
    </Box>
  );
};
