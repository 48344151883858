import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { OtpSignInEmail } from './otp-sign-in-email.organism';
import { OtpSignInPin } from './otp-sign-in-pin.organism';

export const OtpSignInPage = () => {
  const [email, setEmail] = useState('');

  const onSuccessEmailSubmit = (email) => {
    setEmail(email);
  };

  return (
    <Box sx={{ maxWidth: '50rem', m: '0 auto' }}>
      <Stack alignItems="center">
        <>
          {!email && <OtpSignInEmail onSuccess={onSuccessEmailSubmit} />}
          {email && <OtpSignInPin email={email} />}
        </>
      </Stack>
    </Box>
  );
};
